import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios.js";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";
import { getCurrentMonthTimestamps } from "../../Utils/Helper.js";

export const myClassListServices = createAsyncThunk(
  "myClassListServices",
  async (param) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/session/teacher-my-classes-list?status=${param?.status}&itemsPerPage=10&pageNumber=${param?.pageNumber}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      //   console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);


export const joinClassTimeServices = createAsyncThunk(
  "joinClassTimeServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/session/add-teacher-join-class-timestamp`;
      const res = await axios.post(url, payload);
      return res.data;
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }
);



export const endClassServices = createAsyncThunk(
  "endClassServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/session/add-teacher-end-class-timestamp`;
      const res = await axios.post(url, payload);
      return res.data;
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }
);



export const classFeedBackQuestionServices = createAsyncThunk(
  "classFeedBackQuestionServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/static-data/demo-questions-list`;
      const res = await axios.get(url, payload);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);


export const regularClassFeedBackQuestionServices = createAsyncThunk(
  "regularClassFeedBackQuestionServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/static-data/regular-questions-list`;
      const res = await axios.get(url, payload);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);



export const demoFeedBackServices = createAsyncThunk(
  "demoFeedBackServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/feedback/submit-user-feedback`;
      const res = await axios.post(url, payload);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);



export const joinClassServices = async (payload) => {
  let url = `${BASE_URL}/session/join-session-details`;
  const response = await axios.post(url, payload)
  return response.data
}




export const startRecordingServices = createAsyncThunk(
  "startRecordingServices",
  async (payLoad) => {
    try {
      let url = `${BASE_URL}/session/start-recording`;
      const res = await axios.post(url, payLoad);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);




export const endRecordingServices = createAsyncThunk(
  "endRecordingServices",
  async (payLoad) => {
    try {
      let url = `${BASE_URL}/session/end-recording`;
      const res = await axios.post(url, payLoad);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);



export const openSlotServices = createAsyncThunk(
  "openSlotServices",
  async (payLoad) => {
    try {
      let url = `${BASE_URL}/session/open-teacher-session`;
      const res = await axios.post(url, payLoad);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);


export const closeSlotServices = createAsyncThunk(
  "closeSlotServices",
  async (payLoad) => {
    console.log(payLoad);
    try {
      let url = `${BASE_URL}/session/close-teacher-session`;
      const res = await axios.put(url, payLoad);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);




export const sessionCancelCountServices = createAsyncThunk(
  "sessionCancelCountServices",
  async () => {
    try {
      const timestamps = getCurrentMonthTimestamps();
      let url = `${BASE_URL}/teacher/teacher-cancel-session-count/?startDateTimestamp=${timestamps?.startOfMonthTimestamp}&endDateTimestamp=${timestamps?.endOfMonthTimestamp}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      //   console.log(error);
      //   console.log(error);
      handleError(error);
      throw error;
    }
  }
);



export const updateCameraStatus = createAsyncThunk(
  "updateCameraStatus",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/session/update-camera-click-timestamp`;
      const res = await axios.post(url, payload);
      return res.data;
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }
);


export const blockLearnerServices = createAsyncThunk(
  "blockLearnerServices",
  async (payLoad) => {
    try {
      let url = `${BASE_URL}/blocked-users/block-user-request`;
      const res = await axios.post(url, payLoad);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);